import React from "react"

import Layout from "../components/layout"
import { useLocation } from '@reach/router'

import styled from "styled-components"
import { Link } from "gatsby"


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
`

const StyledH1 = styled.h1`
  color: red;
`



export default function Page() {
  const location = useLocation();
  let country ="CH"
  let lang="de-CH"
  
  const url = typeof window !=="undefined" ? window.location.href :"";


  if(url.includes(".com")){
    country ="DE"
  }


  return (

    <Layout location={country} path={location.pathname} language={lang}>
      <Wrapper>
        <StyledH1>Zahlung nicht erfolgreich</StyledH1>
        <p>Ihre Zahlung war leider nicht erfolgreich.</p>
        <p><Link to="/service/?country=DE">Versuchen Sie es erneut </Link> oder kontaktieren Sie uns unter hello@doryhealth.com </p>
      </Wrapper>
    </Layout>
  )
}
